<template>
  <div>
    <Consulta
        ref="filtroConsulta"
        titulo="Consulta de Inscrição"
        :parametros="paramsFiltro"
        :colunas="headers"
        :ordenacao-colunas="sortBy"
        :consulta-callback="consulta"
        :mostrar-expandir="true"
        item-key="oid"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.processoOid"
                :items="processos"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Processos"
                outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.opcaoOid"
                :disabled="!paramsFiltro.processoOid"
                :items="opcoes"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Opção"
                outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.nome"
                dense
                label="Nome"
                name="nomeInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.cpf"
                v-mask="'###########'"
                :counter="11"
                dense
                label="CPF"
                maxlength="11"
                name="cpfInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
                multiple
                v-model="paramsFiltro.situacoesInscricaoOid"
                :items="situacoesInscricao"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Situação Inscrição"
                outlined
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.possuiDocumentacaoPendente="{ item }">
        <v-icon v-if="item.possuiDocumentacaoPendente" color="primary">
          mdi-check
        </v-icon>
        <v-icon v-else>
          mdi-minus
        </v-icon>
      </template>
      <template v-slot:acoesMenu="{ item }">
        <v-list-item>
          <v-btn block text @click.prevent="alterarSituacaoInscricao(item)">
            Alterar Situação
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn block text @click.prevent="listarNecessidadeEspecial(item)">
            Necessidades Especiais
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
              block
              text
              @click.prevent="downloadDocumentosParaVisualizacao(item)"
          >
            Visualizar Documentos
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
              block
              text
              @click.prevent="downloadDocumentos(item)"
          >
            Baixar Documentos
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
              block
              text
              @click.prevent="emitirRecibo(item)"
          >
            Emitir Recibo
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
              block
              text
              @click.prevent="emitirCartaoConfirmacao(item)"
          >
            Emitir Cartão de Confirmação
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn block text @click.prevent="recuperarNotasCandidato(item)">
            Notas
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn block text @click.prevent="marcarDocumentacaoPendente(item)">
            Documentação Pendente
          </v-btn>
        </v-list-item>
      </template>
      <template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
        <v-tooltip
            v-if="item.avaliacao"
            right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="expand(!isExpanded)">
              {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </template>
          <span>Avaliação da Documentação</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-sheet class="my-3" outlined>
            <v-tabs
                v-model="tabDocumentacao"
                show-arrows
                centered
                color="primary"
            >
              <v-tab>
                Avaliação Documentação
              </v-tab>
              <v-tab v-if="item.justificativaCandidato">
                Recurso Candidato
              </v-tab>
              <v-tab v-if="item.parecerAvaliador">
                Parecer Avaliador
              </v-tab>
              <v-tabs-items v-model="tabDocumentacao" touchless>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <div>
                        <v-sheet class="pa-2" outlined>
                          <p style="white-space: pre-line" class="text-justify">
                            {{ item.avaliacao }}
                          </p>
                        </v-sheet>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="mx-2">
                      <v-sheet class="pa-2" outlined>
                        <p style="white-space: pre-line" class="text-justify">
                          {{ item.justificativaCandidato }}
                        </p>
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-sheet class="pa-2" outlined>
                        <p style="white-space: pre-line" class="text-justify">
                          {{ item.parecerAvaliador }}
                        </p>
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-sheet>
          <v-sheet></v-sheet>
        </td>
      </template>
    </Consulta>
    <v-dialog scrollable v-if="dialogDocuments" v-model="dialogDocuments" width="80%">
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Documentos
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="dialogDocuments = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-tabs v-model="documentosTab" show-arrows>
          <v-tab v-for="arquivo in arquivosVisualizacao" :key="arquivo.nome">
            <span class="d-inline-block text-truncate"> {{ arquivo.nome }} </span>
            <v-chip v-if="!!arquivo.faseEnvio"
                    text-color="white"
                    color="info"
                    class="ml-1"
                    small>
              <span class="text-truncate">
                  {{ arquivo.faseEnvio }}
              </span>
            </v-chip>
          </v-tab>
        </v-tabs>
        <v-card-text style="overflow: hidden">
          <v-tabs-items v-model="documentosTab">
            <v-tab-item
                v-for="arquivo in arquivosVisualizacao"
                :key="arquivo.nome"
            >
              <DocumentsViewer :file="arquivo"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="dialogDocumentacaoPendente"
        v-model="dialogDocumentacaoPendente"
        width="500"
        @click:outside="cancelarCriacaoDocumentacaoPendente"
        scrollable
    >
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Marcar Documentação Pendente
        </v-card-title>
        <v-card-text>
          <v-form
              ref="documentacaoPendenteForm"
              @submit.prevent="criarDocumentacaoPendente"
              class="mt-3"
          >
            <v-textarea
                v-model="documentacaoPendente"
                dense
                label="Qual a pendência?"
                outlined
                maxlenght="2000"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  @click.prevent="cancelarCriacaoDocumentacaoPendente"
              >
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="dialog"
        v-model="dialog"
        width="500"
        @click:outside="cancelarAlteracaoSituacaoInscricao"
        scrollable
    >
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Alterar Situação da Inscrição
        </v-card-title>
        <v-card-text>
          <v-form
              ref="alterarSituacaoForm"
              @submit.prevent="salvarAlteracaoSituacaoInscricao"
              class="mt-3"
          >
            <v-text-field
                :value="inscricaoEditada.situacaoInscricao"
                dense
                label="Situação Inscrição Atual"
                outlined
                readonly
            ></v-text-field>
            <v-select
                v-model="novaSituacaoInscricaoOid"
                :items="situacoesAlteracao"
                :rules="[campoObrigatorioRule]"
                dense
                item-text="nome"
                item-value="oid"
                label="Nova Situação Inscrição *"
                outlined
            /><v-select
              v-model="justificativaOid"
              :items="justificativas"
              :rules="[campoObrigatorioRule]"
              dense
              item-value="oid"
              label="Justificativa *"
              outlined
          >
            <template v-slot:item="data">
              {{data.item.codigo}} - {{data.item.nome}}
            </template>
            <template v-slot:selection="data">
              {{data.item.codigo}} - {{data.item.nome}}
            </template>
          </v-select>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  @click.prevent="cancelarAlteracaoSituacaoInscricao"
              >
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogNotas" v-model="dialogNotas" width="500">
      <v-card>
        <v-card-title class="primary white--text">
          Notas
          <v-spacer/>
          <v-btn icon color="white" @click="dialogNotas = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="notasInscricaoSelecionada.length" class="mt-8">
          <v-row align="center" justify="center">
            <v-col
                cols="5"
                v-for="nota in notasInscricaoSelecionada"
                :key="nota.tiponota">
              <v-text-field
                  v-model="nota.valorNota"
                  :label="'Nota ' + nota.tipoNota"
                  outlined
                  readonly
                  dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else class="justify-center pa-8">
          <v-row align="center" justify="center">
            <v-alert type="info" border="left" colored-border outlined>
              Não existem notas para a inscrição selecionada.
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogNE" v-model="dialogNE" width="600" >
      <v-card>
        <v-card-title class="primary white--text">
          Necessidades Especiais
          <v-spacer/>
          <v-btn icon color="white" @click="fecharDialogNE">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-6" >
          <h3>Nome: {{ itemNE.nome }}</h3>
          <h3>CPF: {{ itemNE.cpf }}</h3>
          <h3>Curso: {{ itemNE.nomeOpcao }}</h3>
        </v-card-text>
        <v-card-text>
          <v-data-table
              :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [25, 50, 100, 200, 500]
            }"
              :headers="headersNE"
              :items="necessidadesEspeciais"
              calculate-widths
              dense
              v-if="necessidadesEspeciais.length > 0"
          >
            <template v-slot:item.action="{item}">
              <v-btn icon color="red" @click="excluirNecessidadeEspecial(item)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-alert v-else type="info" outlined class="ma-3">
            Candidato não possui necessidade especial.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from "@/commons/rules";
import inscricaoService from "@/services/inscricao.service";
import reciboService from "@/services/recibo.service";
import processoService from "@/services/processo.service";
import opcaoService from "@/services/opcao.service";
import situacaoInscricaoService from "@/services/situacaoInscricao.service";
import documentosService from "@/services/documentos.service";
import {mapActions} from "vuex";
import DocumentsViewer from "@/components/base/DocumentsViewer";
import Consulta from "@/components/pages/admin/consultas/Consulta";
import cartaoConfirmacaoService from "@/services/cartaoConfirmacao.service";
import necessidadeEspecialService from "@/services/necessidadeEspecial.service";
import documentacaoPendenteService from "@/services/documentacaoPendente.service";
import justificativaService from "@/services/justificativa.service";

export default {
  name: "Inscricao",
  components: {Consulta, DocumentsViewer},

  data() {
    return {
      tabDocumentacao: 0,
      dialog: false,
      dialogNE: false,
      headers: [
        {text: "Nome", value: "nome"},
        {text: "CPF", value: "cpf"},
        {text: "Numero ENEM", value: "numeroEnem"},
        {text: "Processo", value: "nomeProcesso"},
        {text: "Inscrição", value: "numeroInscricao"},
        {text: "Nome Opção", value: "nomeOpcao"},
        {text: "Turno Opção", value: "turnoOpcao"},
        {text: "Polo Opção", value: "poloOpcao"},
        {text: "Formação Opção", value: "formacaoOpcao"},
        {text: "Situação Inscrição", value: "situacaoInscricao"},
        {text: "Documentação pendente", value: "possuiDocumentacaoPendente"},
        {
          text: "",
          value: "data-table-expand",
          transient: true,
          align: "center"
        }
      ],
      headersNE: [
        {text: "Tipo", value: "codigo"},
        {text: "Descrição", value: "descricao"},
        {text: "Ações", value: "action"}
      ],
      necessidadesEspeciais: [],
      itemNE: {},
      elementos: [],
      loading: false,
      options: {},
      numTotalElementos: 0,
      sortBy: ["nome"],
      paramsFiltro: {},
      processos: [],
      opcoes: [],
      situacoesInscricao: [],
      justificativas: [],
      inscricaoEditada: {},
      novaSituacaoInscricaoOid: null,
      justificativaOid: null,
      arquivosVisualizacao: [],
      dialogDocuments: false,
      documentosTab: 0,
      auditoriaDialog: false,
      inscricaoAuditoria: null,
      entidadeOid: null,
      notasInscricaoSelecionada: {},
      dialogNotas: false,
      dialogDocumentacaoPendente: false,
      documentacaoPendente: "",
      documentacaoPendenteOid: null,
      consulta: inscricaoService.recuperarFiltro
    };
  },

  watch: {
    "paramsFiltro.processoOid": {
      handler(oid) {
        if (oid) {
          opcaoService
              .recuperarOpcoesConsulta(oid)
              .then(response => {
                this.opcoes = response.data;
              })
              .catch(() => {
              });
        } else {
          this.paramsFiltro.opcaoOid = null;
        }
      },
      deep: true
    }
  },

  async created() {
    await processoService
        .recuperarProcessosConsulta()
        .then(response => {
          this.processos = response.data;
        })
        .catch(() => {
        });

    await situacaoInscricaoService
        .recuperarSituacoesInscricao()
        .then(response => {
          this.situacoesInscricao = response.data;
        });

    await justificativaService
        .recuperarJustificativas()
        .then(response => {
          this.justificativas = response.data;
        });
  },

  async mounted() {
    if (this.$route.params.processoOid) {
      this.paramsFiltro.processoOid = this.$route.params.processoOid;

      opcaoService
          .recuperarOpcoesConsulta(this.paramsFiltro.processoOid)
          .then(response => {
            this.opcoes = response.data;
          })
          .catch(() => {
          });

      this.$refs.filtroConsulta.mostrarResultado = true;

      await this.$refs.filtroConsulta.consultar();
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    alterarSituacaoInscricao(inscricao) {
      this.dialog = true;
      this.inscricaoEditada = inscricao;
    },

    async marcarDocumentacaoPendente(inscricao) {
      this.inscricaoEditada = inscricao;
      await this.recuperarDocumentacaoPendentePorInscricao(inscricao);
      this.dialogDocumentacaoPendente = true;
    },

    cancelarCriacaoDocumentacaoPendente() {
      this.dialogDocumentacaoPendente = false;
      this.documentacaoPendente = "";
      this.documentacaoPendenteOid = null;
    },

    async recuperarDocumentacaoPendentePorInscricao(inscricao) {
      let loading = this.$loading.show();

      await documentacaoPendenteService.recuperarDocumentacaoPendentePorInscricao(inscricao.oid)
          .then(response => {
            this.documentacaoPendente = response.data.pendencia;
            this.documentacaoPendenteOid = response.data.oid;
          })

      loading.hide();
    },

    async criarDocumentacaoPendente() {
      let loading = this.$loading.show();

      if (this.documentacaoPendenteOid) {
        await documentacaoPendenteService.atualizarDocumentacaoPendente(this.documentacaoPendenteOid, this.documentacaoPendente)
            .then(() => {
              this.exibirAviso({
                mensagem:
                    "Documentação pendente atualizada com sucesso.",
                tipo: "success"
              })
            }).catch(() => {
              this.exibirAviso({
                mensagem:"Não foi possível atualizar a documentação pendente.",
                tipo:"error"
              })
              loading.hide();
            })
      } else {
        await documentacaoPendenteService.criarDocumentacaoPendente(this.inscricaoEditada.oid, this.documentacaoPendente)
            .then(() => {
              this.exibirAviso({
                mensagem:
                    "Documentação pendente criada com sucesso.",
                tipo: "success"
              })
            }).catch(() => {
              this.exibirAviso({
                mensagem:"Não foi possível criar a documentação pendente.",
                tipo:"error"
              })
              loading.hide();
            })
      }

      await this.$refs.filtroConsulta.getDataFromApi();
      this.cancelarCriacaoDocumentacaoPendente();

      loading.hide();
    },

    cancelarAlteracaoSituacaoInscricao() {
      this.dialog = false;
      setTimeout(() => {
        this.novaSituacaoInscricaoOid = null;
        this.justificativaOid = null;
      }, 500);
    },

    async listarNecessidadeEspecial(item) {
      this.itemNE = item;

      let loading = this.$loading.show();

      await necessidadeEspecialService
          .recuperarNecessidadesEspeciaisPorInscricao(item.oid)
          .then(response => {
            this.necessidadesEspeciais = response.data;
          }).catch(() => {
            loading.hide();
          });

      this.dialogNE=true;

      loading.hide();
    },

    async excluirNecessidadeEspecial(item) {
      let loading = this.$loading.show();

      await necessidadeEspecialService.deletarNecessidadeEspecialPorInscricao(item.oid)
          .then( async () => {

            await necessidadeEspecialService
                .recuperarNecessidadesEspeciaisPorInscricao(this.itemNE.oid)
                .then(response => {
                  this.necessidadesEspeciais = response.data;
                });

            this.exibirAviso({
              mensagem:
                  "Necessidade especial excluída com sucesso.",
              tipo: "success"
            })
          }).catch(() => {
            this.exibirAviso({
              mensagem:"Não foi possível excluir a Necessidade Especial selecionada.",
              tipo:"error"
            })
            loading.hide();
          })
      loading.hide();
    },

    fecharDialogNE() {
      this.dialogNE = false;
      this.necessidadesEspeciais = [];
      this.itemNE = {};
    },

    async emitirRecibo(inscricao) {
      let loader = this.$loading.show();

      await reciboService.recuperarReciboPdfAdministrador(inscricao).then(response => {
        const file = new Blob([response.data], {type: "application/pdf"});
        const fileURL = URL.createObjectURL(file);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = "recibo.pdf";
        fileLink.click();
      });

      loader.hide();
    },

    async emitirCartaoConfirmacao(inscricao) {
      let loader = this.$loading.show();

      await cartaoConfirmacaoService
          .recuperarCartaoConfirmacaoAdministrador(inscricao.oid).then(async () => {
            await cartaoConfirmacaoService
                .recuperarCartaoConfirmacaoPdfAdministrador(inscricao.oid)
                .then(response => {
                  const file = new Blob([response.data], {type: "application/pdf"});
                  const fileURL = URL.createObjectURL(file);
                  let fileLink = document.createElement("a");
                  fileLink.href = fileURL;
                  fileLink.download = "cartaoConfirmacao.pdf";
                  fileLink.click();
                });
          }).catch(error => {
            let mensagem = "Um erro ocorreu ao recuperar cartão confirmação";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });

      loader.hide();
    },

    downloadDocumentos(inscricao) {
      documentosService.downloadDocumentosCandidato(inscricao.oid).then(response => {
        const file = new Blob([response.data]);
        const fileURL = URL.createObjectURL(file);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = inscricao.cpf + ".zip";
        fileLink.click();
      }).catch(async error => {
        let mensagem = "Não foi possível realizar download";
        if (error.response.status === 409) {
          await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = function () {
              resolve(JSON.parse(reader.result.toString()));
            };
            reader.readAsText(error.response.data);
          }).then(erro => {
            mensagem = erro.msg;
          }).catch();
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });
    },

    async salvarAlteracaoSituacaoInscricao() {
      if (this.$refs.alterarSituacaoForm.validate()) {
        let loader = this.$loading.show();
        await inscricaoService
            .alterarSituacaoInscricao(
                this.inscricaoEditada.oid,
                this.novaSituacaoInscricaoOid,
                this.justificativaOid
            )
            .then(() => {
              this.exibirAviso({
                mensagem: "Situação da inscrição atualizada com sucesso!",
                tipo: "success"
              });
            })
            .catch(() => {
              this.exibirAviso({
                mensagem: "Inscrição não encontrada",
                tipo: "error"
              });
            });

        await this.$refs.filtroConsulta.getDataFromApi();

        this.cancelarAlteracaoSituacaoInscricao();
        loader.hide();
      }
    },

    async downloadDocumentosParaVisualizacao(inscricao) {
      let loader = this.$loading.show();
      await documentosService
          .downloadDocumentosCandidatoParaVisualizacao(inscricao.oid)
          .then(response => {
            this.arquivosVisualizacao = response.data;
            this.dialogDocuments = true;
          })
          .catch(error => {
            let mensagem = "Não foi possível realizar download";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
      loader.hide();
    },

    async recuperarNotasCandidato(inscricao) {
      await inscricaoService
          .recuperarNotasPorInscricao(inscricao.oid)
          .then(response => {
            this.notasInscricaoSelecionada = response.data;
            this.dialogNotas = true;
          })
          .catch();
    }
  },
  computed: {
    situacoesAlteracao() {
      return this.situacoesInscricao.filter(si => si.codigo !== 'ELMC')
    }
  }
};
</script>

<style scoped></style>
